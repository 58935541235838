















































import Spinner from '@/components/atoms/Spinner.vue'
import { OrganizationGroup } from '@/store/GroupSettings'
import menuStore from '@/store/Menu'
import orgGroupsStore, { UpdateOrderField } from '@/store/OrganizationGroups'
import VABox from 'va/widgets/VABox.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import Draggable from 'vuedraggable'

@Component({ components: { VABox, Spinner, Draggable } })
export default class extends Vue {
  isLoading = false
  isSuccess = false
  errorMessage = ''
  orgGroups: OrganizationGroup[] = []

  get dragOptions(): { animation: number; ghostClass: string } {
    return {
      animation: 200,
      ghostClass: 'ghost',
    }
  }

  get getOrgGroups(): OrganizationGroup[] {
    return orgGroupsStore.sortedOrgGroups
  }

  @Watch('getOrgGroups', { immediate: true })
  setOrgGroup(): void {
    this.orgGroups = this.getOrgGroups
  }

  async update(): Promise<void> {
    this.isLoading = true
    const params = this.createParams()
    try {
      await orgGroupsStore.updateOrder(params)
      if (this.isGroupActive()) {
        await menuStore.fetchGroups()
      }
      this.isSuccess = true
    } catch (e) {
      const error = e as Error
      this.errorMessage = error.message
    } finally {
      this.isLoading = false
    }
  }

  createParams(): UpdateOrderField[] {
    return this.orgGroups.map((orgGroup, i) => {
      return {
        id: orgGroup.id,
        order: i + 1,
      }
    })
  }

  isGroupActive(): boolean {
    return menuStore.groups.length > 0
  }
}
