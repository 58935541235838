import config from '@/config'
import store from '@/store'
import axios from 'axios'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { OrganizationGroup } from '@/store/GroupSettings'

// TODO: いずれGroupSettingsStoreの内容をこちらに統合した方が良いと思われる

export type UpdateOrderField = Pick<OrganizationGroup, 'id' | 'order'>

export const ErrorMsg = {
  ResponseError: (status: number) => 'API response error status:' + status,
  FetchCountFailureError: '現在のグループ数取得に失敗しました',
  FailedToGroupDeleteError: 'グループの解除に失敗しました',
  FetchOrganizationGroupFailureError: '組織グループ取得に失敗しました',
  FailedToUpdateOrderError: 'グループの表示順変更に失敗しました',
} as const

@Module({ dynamic: true, namespaced: true, name: 'organizationGroups', store })
class Mod extends VuexModule {
  count = 0
  orgGroups: OrganizationGroup[] = []
  orgGroupsNoGroupCheck: OrganizationGroup[] = []

  get sortedOrgGroups(): OrganizationGroup[] {
    return this.orgGroups.sort((a, b) => {
      return (a.order ?? 0) - (b.order ?? 0)
    })
  }

  @Mutation
  setCount(count: number): void {
    this.count = count
  }

  @Mutation
  setOrgGroups(orgGroups: OrganizationGroup[]): void {
    this.orgGroups = orgGroups
  }

  @Mutation
  setOrgGroupsNoGroupCheck(orgGroups: OrganizationGroup[]): void {
    this.orgGroupsNoGroupCheck = orgGroups
  }

  @Action({ commit: 'setCount', rawError: true })
  async fetchCount(): Promise<number> {
    const url = `${config.enterprise.apiUrl}/no-contract/organization-groups/count`
    try {
      const res = await axios.get(url)
      return res.data.count
    } catch (e) {
      throw new Error(ErrorMsg.FetchCountFailureError)
    }
  }

  @Action({ rawError: true })
  async deleteNoGroupCheck(organizationGroupId: string): Promise<void> {
    const url = `${config.enterprise.apiUrl}/no-group-check/organization-groups/delete`
    try {
      await axios.post(url, { organizationGroupId: organizationGroupId })
    } catch (e) {
      throw new Error(ErrorMsg.FailedToGroupDeleteError)
    }
  }

  @Action({ rawError: true })
  async updateOrder(orgGroups: UpdateOrderField[]): Promise<void> {
    const url = `${config.enterprise.apiUrl}/organization-groups/reordering`
    try {
      await axios.post(url, { orgGroups })
    } catch (e) {
      throw new Error(ErrorMsg.FailedToUpdateOrderError)
    }
  }

  @Action({ commit: 'setOrgGroups', rawError: true })
  async fetchApprovedOrgGroups(): Promise<OrganizationGroup[]> {
    const url = `${config.enterprise.apiUrl}/organization-groups/approved`
    try {
      const res = await axios.get(url)
      return res.data
    } catch (e) {
      throw new Error(ErrorMsg.FetchOrganizationGroupFailureError)
    }
  }

  @Action({ commit: 'setOrgGroupsNoGroupCheck', rawError: true })
  async fetchOrgGroupsNoGroupCheck(): Promise<OrganizationGroup[]> {
    const url = `${config.enterprise.apiUrl}/no-group-check/organization-groups`
    try {
      const res = await axios.get(url)
      return res.data
    } catch (e) {
      throw new Error(ErrorMsg.FetchOrganizationGroupFailureError)
    }
  }
}

export default getModule(Mod)
