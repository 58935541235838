








import GroupTable from '@/components/organisms/GroupReordering/GroupTable.vue'
import menuStore from '@/store/Menu'
import orgGroupsStore from '@/store/OrganizationGroups'
import { Component, Vue } from 'vue-property-decorator'

@Component({ components: { GroupTable } })
export default class extends Vue {
  async mounted(): Promise<void> {
    menuStore.setActiveFlg({ settings: true })
    await Promise.all([orgGroupsStore.fetchApprovedOrgGroups()])
  }
}
